// src/pages/ithojo-form.js
import React from "react"



import LayoutLp from "../components/layoutLp"
import Seo from "../components/seo"
import LpContents from "../components/lpcontents"
import Timerex from "../components/timerex";

export default function IthojoForm() {

  return (
    <LayoutLp>
      <Seo title="IT導入補助金でEC制作-打ち合わせ予約" description="IT導入補助金で最大350万円おトクにECサイトを入手できる！" />
      <div class="p-contact-end__container p-contact-end__form">
        <div class="p-contact-end__fl">
          <h3 id="cta">WEBミーティング</h3>
          <p>WEBでのミーティングをご希望の方は、下記より日程をお選びください。GoogleMeetを使い、１時間程度のミーティングを想定しております。</p>
        </div>
        <div class="p-contact-end__web">

          <div id="timerex_calendar" data-url="https://timerex.net/s/jpholic_team/e4d3e063"></div>
          <Timerex />
          <script id="timerex_embed" src="https://asset.timerex.net/js/embed.js"></script>
          <script type="text/javascript">
            TimerexCalendar();
          </script>

        </div>
      </div>
    </LayoutLp>

  )
}