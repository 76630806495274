import React, { useEffect } from 'react';

const Timerex = () => {

  useEffect(() => {
    // スクリプトの読み込み
    const script = document.createElement('script');
    script.src = "https://asset.timerex.net/js/embed.js";
    script.id = "timerex_embed";
    script.async = true;

    script.onload = () => {
      // スクリプトの読み込みが完了したら関数を実行
      if (window.TimerexCalendar) {
        window.TimerexCalendar();
      }
    };

    document.body.appendChild(script);

    return () => {
      // ページから移動するときにスクリプトを削除
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div>

    </div>
  );
}

export default Timerex;
